import React from "react";
import { Route, Routes } from "react-router-dom";
import Team from "../components/Team";
import Home from "../components/Home";
import { NavRoutes } from "../constants/routes";

const NavRouter: React.FC = () => {
  const { HOME, TEAM } = NavRoutes;
  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
      <Route path={TEAM} element={<Team />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default NavRouter;
