import jeremy from "../assets/Jeremy_new_hs_SS.png";
import peter from "../assets/Peter_May_HS_SS.png";
import simon from "../assets/simon_ulbrich_hs.png";
import audrey from "../assets/Audrey_HS_3_SS_NB.png";

export const founders = [
    {
      image: jeremy,
      founder: true,
      firstName: "Jeremy",
      title: "CEO",
      lastName: "Heitman",
      linkedin: "https://www.linkedin.com/in/jeremy-heitman/",
      blurb:
        "Jeremy brings over 15 years in support roles and as a team manager in restaurant technology & engineering.",
    },
    {
      image: peter,
      founder: true,
      firstName: "Peter",
      linkedin: "https://www.linkedin.com/in/peter-turn-may/",
      title: "CTO",
      lastName: "May",
      blurb:
        "Peter has over 10 years technology, sales, and operations management. Working both nationally and internationally with a wide range of industries.",
    },
    {
      image: audrey,
      founder: false,
      firstName: "Audrey",
      linkedin: "https://www.linkedin.com/in/audreymaymaure/",
      title: "Director of Marketing and Branding",
      lastName: "May",
      blurb:
      "Audrey brings over 13 years of experience in marketing, communications, and team management across a wide range of industries. As a former entrepreneur and businesswoman, she is a significant part of our team.",
    },
    {
      image: simon,
      founder: false,
      firstName: "Simon",
      linkedin: "https://www.linkedin.com/in/simonjulbrich/",
      title: "Senior Developer",
      lastName: "Ulbrich",
      blurb:
      "Simon possesses 10 years of experience in restaurant IT and enterprise software. He played a pivotal role as the lead developer in establishing Brinker's (Chili's) cloud infrastructure. Beyond work, he finds pleasure in remote work settings and embarking on cross-country tours with his family.",
    },
  ];